import React from 'react';
import { Routes, Route } from 'react-router-dom';
import IntroPage from './IntroPage';
import RecruiterPage from './RecruiterPage';
import FriendPage from './FriendPage';

function App() {
  return (
      <Routes>
        <Route path="/" element={<IntroPage />} />
        <Route path="/recruiter" element={<RecruiterPage />} />
        <Route path="/friend" element={<FriendPage />} />
      </Routes>
  );
}

export default App;