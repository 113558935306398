import React, { useState, useEffect } from 'react';
import './RecruiterPage.css';
import { useNavigate } from 'react-router-dom';
import logo from './logo.png';
import { motion } from 'framer-motion';
import axios from 'axios';

const RecruiterPage = () => {
  const navigate = useNavigate(); 
  const [activeText, setActiveText] = useState(''); 
  const [displayedText, setDisplayedText] = useState(''); 
  const [typingIndex, setTypingIndex] = useState(0); 
  const [isQuestionSelected, setIsQuestionSelected] = useState(false); 
  const [freeText, setFreeText] = useState('ask your own question! [ai generated]'); 
  const [customResponse, setCustomResponse] = useState(''); 
  const [loading, setLoading] = useState(false); // Loading state for AI response

  

  // Predefined text for each button
  const responses = {
    first: "I currently work at Hilti Incorporated, located in Plano, TX.\n\nI have worked at Hilti since September 2018, holding various positions within the Digital & IT organizations.",
    second: "I am a Technical Product Owner on the Platform Engineering Team.\n\nMy role is to lead a team of expert engineers, improving the technical state of our global Digital team.\n\nWe maintain core infrastructure, guide Digital feature teams on solutions and design, and implement new tools to keep Hilti ahead of the competition.",
    third: `I’m a technical person by nature, but proficient in translating 'business talk' between teams. 
    \nI consistently sharpen my technical skills alongside my Product Owner experience.\n\n
    This includes:\n
    • Certifications in AWS, Apollo GraphQL, Google Analytics\n
    • Technical projects including Web Applications and this site\n
    • Development work in FE (Angular), JS, PHP, SQL, and database management at Hilti.`    
  };

      // Google Analytics
      useEffect(() => {
        const script1 = document.createElement('script');
        script1.async = true;
        script1.src = "https://www.googletagmanager.com/gtag/js?id=G-BKK7KS6N4C";
        document.head.appendChild(script1);
    
        const script2 = document.createElement('script');
        script2.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-BKK7KS6N4C');
        `;
        document.head.appendChild(script2);
      }, []);

  const handleLogoClick = () => {
    navigate('/'); 
  };

  const handleClick = (key) => {
    setActiveText(responses[key]); 
    setDisplayedText(''); 
    setTypingIndex(0); 
    setIsQuestionSelected(true); 
    setCustomResponse(''); // Clear custom response
  };

  const handleSubmitCustomQuestion = async () => {  
    console.log('Submitting question:', freeText);
    if (freeText === '' || freeText === 'ask your own question! [ai generated]') return; // Prevent empty submissions
    setLoading(true); // Show loading indicator
    setCustomResponse(''); // Clear the previous custom response
    try {
      const response = await axios.post('https://api.meetamaj.com/ask', { question: freeText });
      setCustomResponse(response.data.answer);
      setDisplayedText(''); // Clear the predefined response
      setTypingIndex(0); 
      setIsQuestionSelected(true); // Show the response in the middle
    } catch (error) {
      console.error('Error fetching AI response:', error);
      setCustomResponse('Sorry, there was an error fetching the response.');
    } finally {
      setLoading(false); 
    }
  };

  // Allow pressing "Enter" to submit custom questions
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmitCustomQuestion();
    }
  };

  // Typewriter effect logic for both predefined and custom responses
  useEffect(() => {
    if ((activeText || customResponse) && typingIndex < (activeText.length || customResponse.length)) {
      const timeoutId = setTimeout(() => {
        const textToType = customResponse || activeText;
        setDisplayedText(prev => prev + textToType[typingIndex]);
        setTypingIndex(typingIndex + 1);
      }, 25); // Quick typing effect

      return () => clearTimeout(timeoutId); 
    }
  }, [activeText, customResponse, typingIndex]);

  return (
    <div className="recruiter-page">
      {/* Logo and Name Section */}
        <div className="header" onClick={handleLogoClick}>
        <img src={logo} alt="Logo" className="logo" />
        <h1 className="logo-text">meet Amaj</h1>
      </div>

      {!isQuestionSelected && (
        <motion.div
          className="main-content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
        <p className="subheading">what would you like to know?</p>
      </motion.div>
      )}

      {/* Buttons fixed at the top */}
      <motion.div
      className="fixed-buttons"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, delay: 0.5 }}
      >
        <button className="question-button" onClick={() => handleClick('first')}>where do you work?</button>
        <button className="question-button" onClick={() => handleClick('second')}>what do you do?</button>
        <button className="question-button" onClick={() => handleClick('third')}>what are your skills?</button>
      
      </motion.div>

      {/* Input field for custom questions and response */}
      <motion.div 
        className="main-content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        {/* Custom Question Input */}
        <textarea
          className="custom-question"
          value={freeText}
          onFocus={() => freeText === 'ask your own question! [ai generated]' && setFreeText('')} // Clear the default text on focus
          onBlur={() => freeText === '' && setFreeText('ask your own question! [ai generated]')} // Restore default text if the field is empty
          onChange={(e) => setFreeText(e.target.value)}
          onKeyDown={handleKeyDown} // Allow submitting by pressing Enter
        />

      </motion.div>

      {/* Loading spinner */}
      {loading && <p className="loading-text">let me think...</p>}

      {/* Fade-in for typewriter response */}
      <motion.div
        className="center-content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        <p className="typewriter-text">{displayedText}</p>
      </motion.div>
    </div>
  );
};

export default RecruiterPage;